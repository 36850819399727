.payrollContainer {
  margin: 0;
  font-family: Varela Round, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.payrollWrapper h1 {
  font-family: Varela Round, Helvetica, Arial, sans-serif;
  font-size: 32px;
  /* color: black; */
  color: #003b66;
  font-weight: 700;
  margin: 10px 0 30px;
  padding: 0;
}

.payrollWrapper h2 {
  font-family: Varela Round, Helvetica, Arial, sans-serif;
  font-size: 25.5px;
  /* color: black; */
  color: #003b66;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 30px;
}

.payrollWrapper p {
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0px;
  color: black;
  /* color: #003b66; */
}

.payrollWrapper tr {
  background-color: white;
}

.stylingLine {
  color: #e85412;
  margin-top: -18px;
  margin-bottom: 30px;
  width: 30px;
  font-weight: bolder;
  border-color: #e85412;
}

.section {
  border-top: 1px solid #e5e5e5;
  margin-top: 2rem;
  padding-top: 1rem;
}

.section > p:first-child {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1rem;
  color: #003b66;
}

.alertTitle {
  margin: 0px;
  font-weight: bold;
}

p.alertText {
  margin: 0px;
  font-size: 14px;
}

/* Styles */

.payrollWrapper {
  display: flex;
  padding: 0rem 1rem 3rem 3rem;
  margin-top: 1rem;
}

.payrollContainer {
  width: 100%;
  max-width: 600px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.table {
  width: calc(100% - 3rem);
  max-width: 1000px;
  margin-left: 3rem;
  border-left: 3px solid #f6f6f6;
  padding-left: 3rem;
}

tr.fee {
  background-color: #f8d5c6;
  font-size: 17px;
}

.fee-top td {
  border-top: none !important;
  border-bottom: 1px solid white !important;
}

.fee-middle td {
  border-bottom: 1px solid white !important;
}

.fee-top td:first-child {
  border-top-left-radius: 10px !important;
}

.fee-top td:last-child {
  border-top-right-radius: 10px !important;
}

.fee-bottom td {
  border-top: 1px solid white !important;
  border-bottom: none;
}

.fee-bottom td:first-child {
  border-bottom-left-radius: 10px !important;
  border-bottom: none;
}

.fee-bottom td:last-child {
  border-bottom-right-radius: 10px !important;
  border-bottom: none;
}

.fee-noBorder td {
  border-bottom: none !important;
}

p.home_headerNumber {
  font-weight: bold;
  text-align: right;
  line-height: 24px;
  margin: 0px;
  font-size: 17px;
}

p.home_headerNumber b {
  font-weight: bold;
}

p.home_headerNumberFirst {
  margin-bottom: 0px;
}

p.home_subheaderNumber {
  text-align: right;
  line-height: 22px;
  margin: 0px;
  font-size: 16px;
}

.home_warning {
  display: flex;
  vertical-align: center;
  margin-top: 2rem;
}

.home_warning > div {
  margin-right: 1rem;
  color: #e85412;
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline > p {
  margin: 0px;
}

.print_icon {
  padding: 0.2rem;
  /* background-color: #e85412; */
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: end;
  border-radius: 10px;
  /* color: white; */
  color: #003b66;
  /* color: #e85412; */
  cursor: pointer;
  font-size: 14px;
  flex-shrink: 1;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: -15px;
  transition: 0.2s;
  align-items: center;
  min-width: 80px;
}

.print_icon:hover {
  background-color: white;

  /* color: white; */
  /* color: #003b66; */
  color: #e85412;
}

.print_icon:hover p {
  background-color: white;

  /* color: white; */
  /* color: #003b66; */
  color: #e85412;
}

.print_icon p {
  font-weight: 600;
  margin: 0 0 0 0.5rem;
  font-size: 14px;
  line-height: 29px;
  transition: 0.2s;
  color: #003b66;
}

.compact {
  /* min-width: 300px; */
  display: flex;
  flex-direction: row;
}

.zielwert_container {
  min-width: 245px;
}

.combofield {
  display: flex;
  flex-direction: row;
}

.combofield > div:first-child {
  margin-right: 6px;
  min-width: 160px;
}

.combofield > div {
  width: 55%;
}

.compact > div {
  flex-grow: 1;
  margin-right: 1rem;
}

.compact > div:last-child {
  margin-right: -0.5rem;
  min-width: 120px;
  margin-bottom: 0;
}
.combofield {
  display: flex;
  flex-direction: row;
}

.combofield > div:first-child {
  margin-right: 6px;
  min-width: 160px;
}
.combofield > div {
  width: 55%;
}

.compact > div {
  flex-grow: 1;
  margin-right: 1rem;
}

.compact > div:last-child {
  margin-right: -0.5rem;
  min-width: 120px;
}
.sollstunden_container {
  min-width: 410px;
}

.toggle_show {
  margin-top: 1rem;
  display: block;
}

.toggle_hidden {
  display: none;
}

.toggle_button {
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: 0.2s;
}

.toggle_button:hover {
  /* background-color: #f6f6f6; */
  background-color: rgba(0, 59, 102, 0.075);
}

.languages {
  display: none;
  /* display: flex; */
}

.languages div {
  padding: 0 0.5rem;
  cursor: pointer;
}

.languages div p {
  margin: 0px;
}

.languages div:first-child {
  border-right: 1px solid lightgray;
}

.active {
  font-weight: bold;
}

.payroll-combo {
  display: flex;
  flex-direction: row;
}

.payroll_basis {
  min-width: 180px;
  margin-top: -25px;
}
.combo_elements {
  margin: 0 10px 0 10px;
  min-width: 120px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* margin-left: -0.5rem; */

  margin-bottom: -10px;
}

.checkbox-container-quell {
  display: flex;
  align-items: center;
  /* margin-left: -0.5rem; */
  margin-top: -5px;
  margin-top: -8px;
  margin-bottom: 20px;
}

.checkbox-container-quell p {
  font-family: Varela Round, Helvetica, Arial, sans-serif;
  font-size: large;
  font-weight: bold;
  color: #003b66;
  margin-right: 5px;
}

.checkbox-container-quell div {
  margin-left: 2px;
}

.checkbox-label {
  font-size: 17px;
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 20px;
  margin-top: 0px;
}

.info_container {
  margin-left: 1rem;
  height: 100%;
  /* display: flex; */
  margin-top: -1px;
  margin-bottom: -1px;
  color: #e3581c;
}

.select_error {
  display: flex;
  flex-direction: column;
}

.select_error p,
.error_field p {
  font-size: 12px;
  color: red;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.source > div {
  width: calc(50% - 0.5rem);
}

.provision {
  display: flex;
  flex-direction: row;
}

.add_provision {
  margin: -15px 0 -15px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/* provisions */
.compact_prov {
  display: flex;
  flex-direction: row;
}

.compact_prov > div {
  flex-grow: 1;
  margin-right: 1rem;
}

.compact > div:last-child {
  margin-right: 0rem;
}

.source_prov > div {
  width: calc(50% - 0.5rem);
}

.entwurf_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.spar_betrag {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/*household switch*/

.children_household {
  display: flex;
  flex-direction: column;
}

.household_switch {
  display: flex;
  flex-direction: row;
  margin-top: -5px;
  margin-bottom: 15px;
}

.switch_label {
  color: black;
}

.switch {
  margin-top: -6px;
  margin-left: 1px;
}

@media only screen and (max-width: 1500px) {
  .compact {
    flex-direction: column;
  }

  .compact_prov {
    flex-direction: column;
  }

  .compact > div {
    margin-right: 0;
  }

  .compact_prov > div:first-child {
    margin-right: 0;
    margin-bottom: -40px;
  }

  .combofield label {
    display: none;
  }

  .combofield > div {
    margin-top: 0;
  }

  .print_icon {
    margin-left: 0rem;
  }

  .source > div {
    width: 100%;
  }

  .source_prov > div {
    width: 100%;
  }

  .combofield label {
    display: none;
  }

  .combofield > div {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1050px) {
  .payrollContainer {
    max-width: 100%;
  }
  .payrollWrapper {
    flex-direction: column;
    padding: 1rem;
  }

  .compact > div {
    margin-right: 1rem;
  }

  .compact_prov > div {
    margin-right: 1rem;
  }

  .source_prov > div:last-child {
    margin-left: 1rem;
  }

  .table {
    margin-left: 0px;
    border: 0px;
    padding-left: 0px;
    margin-top: 2rem;
    border-top: 3px solid #f6f6f6;
    padding-top: 3rem;
    width: 100%;
    overflow-x: auto;
  }

  .print_icon {
    margin-left: 0rem;
  }

  .source > div {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
  }

  .source_prov > div {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
  }

  .compact {
    flex-direction: row;
  }

  .compact_prov {
    flex-direction: row;
  }

  .combofield > div {
    margin-top: 33px;
  }
}

@media only screen and (max-width: 600px) {
  .compact {
    flex-direction: column;
  }

  .compact_prov {
    flex-direction: column;
  }

  .compact > div {
    margin-right: 0;
  }

  .compact_prov > div {
    margin-right: 0;
  }

  .source > div {
    width: 100%;
  }

  .source_prov > div {
    width: 100%;
  }
  .source_prov > div:last-child {
    margin-left: 0;
  }
  .combofield label {
    display: none;
  }

  .combofield > div {
    margin-top: 0;
  }
}
