.info_container {
  margin-left: 1rem;
  height: 100%;
  display: flex;
  margin-top: -1px;
  margin-bottom: -1px;
  align-items: flex-start;
  justify-content: center;
}

.info_icon {
  font-size: 24px !important;
  /* color: #007aff; */
  color: #62a5e1;
  cursor: pointer;
}
.info_icon:hover {
  color: #1e609a;
}

.info_popover {
  padding: 1rem;
  max-width: 500px;
}

.info_popover p {
  margin: 0px;
}
