.payrollWrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.payrollWrapper .print_table {
  font-family: "Titillium Web", Helvetica, Arial, sans-serif !important;
  color: #212121 !important;
  font-size: 12px !important;
}

.payrollWrapper thead.print_head {
  width: 2000px !important;
  background-color: #e85412 !important;
}

.payrollWrapper thead.print_head th {
  padding: 0.275rem !important;
  color: white !important;
  text-align: right !important;
}

.payrollWrapper thead.print_head th:first-child {
  text-align: left !important;
}

.payrollWrapper .simple_row td {
  padding: 0.225rem !important;
  color: #212121 !important;
  text-align: right !important;
  border-bottom: 0.35px solid rgba(224, 224, 224, 1) !important;
  line-height: 12px !important;
}

.payrollWrapper .simple_row td:first-child {
  text-align: left !important;
}

.payrollWrapper .rowbold td {
  padding: 0.25rem !important;
  color: #212121 !important;
  text-align: right !important;
  border-bottom: 0.35px solid rgba(224, 224, 224, 1) !important;
  font-weight: bold !important;
  line-height: 12px !important;
}

.payrollWrapper .rowbold td:first-child {
  text-align: left !important;
}

.payrollWrapper .subrow td {
  padding: 0.1rem 0.4rem !important;
  color: #212121 !important;
  text-align: right !important;
  background-color: #f6f6f6 !important;
  border-bottom: 0.5px solid rgba(224, 224, 224, 1) !important;
  font-size: 10px !important;
}

.payrollWrapper .subrow td:first-child {
  text-align: left !important;
  padding-left: 1rem !important;
}
.payrollWrapper .subrowbold td {
  padding: 0.1rem 0.4rem !important;
  color: #212121 !important;
  text-align: right !important;
  background-color: #f6f6f6 !important;
  border-bottom: 0.5px solid rgba(224, 224, 224, 1) !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.payrollWrapper .subrowbold td:first-child {
  text-align: left !important;
  padding-left: 1rem !important;
}

.payrollWrapper .empty td {
  padding: 0.2rem !important;
  line-height: 8px !important;
}

.payrollWrapper .grid_header td {
  background-color: #e85412 !important;
  padding: 0.275rem !important;
  color: white !important;
  text-align: right !important;
  font-weight: bold !important;
}

.payrollWrapper .grid_header td:first-child {
  text-align: left !important;
}

.payrollWrapper .lastrow td {
  padding: 0.2rem !important;
  color: #212121 !important;
  text-align: right !important;
  border-bottom: 0.5px solid rgba(224, 224, 224, 1) !important;
  font-size: 10px !important;
  background-color: #f2f8fc !important;
}

.payrollWrapper .lastrow td:first-child {
  text-align: left !important;
}

.text td {
  text-align: left !important;
  font-size: 10px !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 12px !important;
  color: #212121 !important;
}
