.line_label {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.line_container p {
  margin: 0 1rem 0 0;
}

.line_subContainerOpen td {
  background-color: #f6f6f6;
  height: 100%;
  transition: all 0.3s linear;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.line_subContainer {
  position: relative;
}

.line_subContainerLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line_subContainer p {
  font-size: 14px;
  margin: 0px;
  line-height: normal;
}

.line_subContainerClosed td {
  background-color: #f6f6f6;
  height: 0%;
  overflow: hidden;
  transition: all 0.2s linear;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 0px #f6f6f6;
}

.line_subContainerClosed p {
  font-size: 0px !important;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.line_subContainerClosed .line_info {
  display: none;
}

.line_subContainerOpen p {
  font-size: 14px;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.line_icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.line_iconOpen {
  transform: rotate(-360deg); /* Equal to rotateZ(45deg) */
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
  /* color: gray; */
  color: #e3581c;
}

.line_iconClosed {
  transition: transform 0.3s ease-in-out;
  /* color: #e3581c; */
  color: #003b66;
}

.line_iconClosed:hover {
  color: #1e609a;
}

p.line_number {
  text-align: right;
  margin: 0px;
}

.line_total {
  background-color: #f6f6f6;
}

.line_total p {
  font-weight: bold !important;
}

.line_fee {
  background-color: #f8d5c6;
  border-bottom: 1px solid white !important;
}

.line_fee td {
  background-color: #f8d5c6;
  border-bottom: 1px solid white !important;
}
.print_icon_small{
  display: inline;
  vertical-align: middle;
  color: #003b66;
}