.container {
  display: table;
  border-radius: 32px;
  border-radius: 0px;
  // background-color: #e85412;
  background-color: #003b66;
  border: 2px solid #003b66;
  padding: 8px 22px;
  color: white;
  font-size: 20px;
  line-height: 32px;
  margin: 2rem auto;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #e85412;
    border: 2px solid#e85412;
    color: white;
  }
}
