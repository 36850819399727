.hover_contact:hover {
  background-color: white;
}
.hover_contact_div:hover {
  color: #e85412;
}

.vertrag_entwurf_button {
  font-size: 12;
  font-family: Varela Round, Helvetica, Arial, sans-serif;
  margin-bottom: -15;
}

.pop_up_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;

  //  #003b66;
  // opacity: 0.1;
  border: 1.5px solid#9b9b9b;
  border-radius: 22.5px;
  border-color: darkgray;

  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 8px 20px 0px;
}

.pop_up_box:focus {
  outline: none;
}

.post_land_combo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.postleitzahl {
  display: flex;
  flex-direction: column;
}
.title {
  margin: -25px 0 15px 0px;
  color: #e85412;
}

.title b {
  color: #e85412;
}

.subtext {
  font-size: 17px;
  font-weight: 200;
  line-height: 25.5px;
  margin-bottom: 0.5rem;
  // color: #000;
  color: #9b9b9b;
}

// .button_send {
//     border: 2px solid #e3581c;
//     border-radius: 32px;
//     width: 150px;
//     height: 50px;
//     color: white;
//     background-color: #e3581c;

// }

// .button_send:hover {
//     background-color: white;
//     color: #e3581c;
// }

.button_combo {
  display: flex;
  flex-direction: row;
  margin: -2rem 0;
}

.contactmodal {
  overflow: auto;
  max-height: 100vh;
  display: flex;
  color: black !important;
}

.gdpr_label {
  font-size: 12px;
  font-weight: 200;
  line-height: 16px;
  margin-bottom: 0rem;
  // color: #000;
  color: #9b9b9b;
}

.gdpr_label a {
  //   color: #e85412;
  // color: #003b66;

  color: #e85412;
}

.highlight_off {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: -25px -15px;
  background-image: "url(../../Highlight_off_icon.png)";
}

.highlight_off img {
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .contactmodal {
    overflow: auto;
  }

  .pop_up_box {
    border-radius: 0;
    box-shadow: 12px;
    position: relative;
    display: flex;
    padding: 0px;
  }

  .pop_up_box_content {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: white;

    padding: 24px;
  }

  .post_land_combo {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }

  .postleitzahl {
    display: flex;
    flex-direction: column;
  }
  .button_combo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0px;
  }

  .button_combo > div {
    margin: 0.5rem 0px !important;
    text-align: center;
  }

  .button-container {
    margin: 0.5rem;
  }

  // .button_combo:first-child {
  //     margin: calc(52% - 5rem);

  // }

  .button_combo > div {
    margin-bottom: 25px;
  }
}
