.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.title {
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  margin-bottom: 0.5rem;
  // color: black;
  color: #5a5a5a;
}

.field {
  border: none;
  padding: 10px 28px;
  border-radius: 32px;
  background-color: #f6f6f6;

  background-image: url("../../assets/img/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 28px) 50%;
}

// .select {
//   &:after {
//     border-bottom-color: "darkred";
//   }
//   & .MuiSvgIcon-root {
//     color: "green";
//   }
// }

.inline {
  padding: 5px 0px 5px 18px;
}

.inline .field {
  padding: 5px 18px;
  background-image: url("../../assets/img/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 50%;
  margin: 0px;
  font-size: 12px !important;
}

.field div div {
  font-size: 16px !important;
}

.inline .field div {
  font-size: 16px !important;
}

.inline .title {
  display: none;
}

.inline {
  margin: 0px;
}

.noTitle {
  margin-top: 26px;
}
.label {
  display: flex;
  align-items: flex-start;
}
