.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.title {
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  margin-bottom: 0.5rem;
  height: 25.5px;
  color: #9b9b9b;
}

.field {
  border: none;
  padding: 10px 28px;
  border-radius: 32px;
  background-color: #f6f6f6;
  position: relative;
  display: flex;
  align-items: center;
}

.addon {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 20px;
  margin: 0px;
  color: #9b9b9b;
}

.inline .field {
  padding: 5px 18px;
  margin: 0px;
  min-height: 10px;
}

.field div input {
  min-height: 30px;
  max-height: 37px;
  font-size: 16px !important;
  background-color: transparent;
  color: black;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px;
}

.inline .field div input {
  font-size: 16px !important;
  min-height: 30px;
}

.inline .title {
  display: none;
}

.inline {
  margin: 0px;
}

.fixed {
  margin-right: 1rem;
}

.label {
  display: flex;
  align-items: flex-start;
}
